<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="m-t">
      <e-row class="erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="juiz.id" disable></erp-input>
          </erp-s-field>
        </e-col>

        <e-col>
          <tipo-pessoa-select label="Tipo:" field-view="lr" simple-border="" v-model="juiz.type" />
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              :label="juiz.type === 1 ? 'Nome completo:' : 'Razão social:'"
              label-width="110px"
          >
            <erp-input simple-border v-model="juiz.name" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 340px">
          <erp-s-field
              view="lr"
              :label="juiz.type === 1 ? 'CPF:' : 'CNPJ:'"
              label-width="40px"
          >
            <erp-input simple-border v-model="juiz.document" v-mask="'###.###.###-##'"
                       v-if="juiz.type === 1" />
            <erp-input simple-border v-model="juiz.document" v-mask="'##.###.###/####-##'" v-else />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="110px"
          >
            <erp-input simple-border v-model="juiz.emails[0].email" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Celular:"
              label-width="50px"
          >
            <erp-input simple-border v-model="juiz.phoneNumbers[0].phoneNumber"
                       v-mask="'(##) # ####-####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Residencial:"
              label-width="70px"
          >
            <erp-input simple-border v-model="juiz.phoneNumbers[1].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Comercial:"
              label-width="70px"
          >
            <erp-input simple-border v-model="juiz.phoneNumbers[2].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item name="endereco">Endereço</erp-tab-item>
      <erp-tab-item name="contato">Contato</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'arrematante-documentos': tabActive === 'documentacao'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row mr>
              <e-col style="min-width: 280px">
                <erp-s-field
                    view="lr"
                    :label="isPessoaFisica ? 'RG:' : 'Inscrição Estadual'"
                    label-width="90px"
                >
                  <erp-input v-model="juiz.documents[0].identifier" />
                </erp-s-field>
              </e-col>

              <e-col v-if="isPessoaFisica">
                <erp-s-field
                    view="lr"
                    label="Emissor:"
                >
                  <erp-input v-model="juiz.documents[0].agentDispatcher" />
                </erp-s-field>
              </e-col>

              <e-col v-if="isPessoaFisica">
                <erp-s-field
                    view="lr"
                    label="Expedição:"
                >
                  <erp-input v-model="juiz.documents[0].expedition" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isPessoaFisica" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nascimento:"
                    label-width="90px"
                >
                  <erp-input v-model="juiz.birthDate" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nacionalidade:"
                >
                  <erp-input v-model="juiz.nationality" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Sexo:"
                >
                  <erp-select placeholder="Selecione"
                              :options="[{label: 'Masculino', value: 1},{label: 'Feminino', value: 2}]"
                              v-model="juiz.gender" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isEmpresa" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Dt. Nasc. (Sócio):"
                    label-width="90px"
                >
                  <erp-input v-model="juiz.birthDate" style="max-width: 100px" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>
            </e-row>

          </e-col>
        </e-row>
      </div>
      <endereco v-show="tabActive === 'endereco'" class="wrapper m-t" :entidade="juiz" />
      <contato v-show="tabActive === 'contato'" class="wrapper m-t" :entidade="juiz" />
    </window-body>
    <window-footer class="flex content-between">
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 v-if="!id" class="m-t">Juiz registrado com sucesso!</h5>
    <h5 v-else class="m-t">Juiz atualizado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  WindowContent,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../../layout/window/Loading'
import TimerCloseWindow from '../../../../layout/window/TimerClose'
import WindowSuccess from '../../../../layout/window/Success'
import {date} from 'uloc-vue'

import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import {EstadoCivil, ESTADO_CIVIL_CASADO, RegimesCasamento} from '@/domain/arrematante/helpers/Status'
import {adjustPurposes} from "../../../../../domain/pessoa/helpers/Purpose";
import {TIPO_PESSOA_FISICA, TIPO_PESSOA_JURIDICA} from "../../../../../domain/pessoa/helpers/Status";
import Endereco from '../../include-tmp/Endereco'
import Contato from '../../include-tmp/Contato'

import {mockEndereco} from '../../../../pessoa/mock/endereco'
import {mockTelefone} from '../../../../pessoa/mock/telefone'
import {mockEmail} from '../../../../pessoa/mock/email'
import {mockDocument} from '../../../../pessoa/mock/document'
import {mockPessoa} from "../../../../pessoa/mock/pessoa";
import {newJuiz, find, updateJuiz} from "../../../../../domain/processos/services/juiz";
import TipoPessoaSelect from "../../../../pessoa/components/include/TipoPessoaSelect";

export default {
  props: ['id'],
  components: {
    TipoPessoaSelect,
    Contato,
    Endereco,
    ECol,
    ERow,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    ErpSelect
  },
  data () {
    return {
      juiz: JSON.parse(JSON.stringify(mockPessoa)),
      loading: !!this.id,
      status: null,
      errors: null,
      success: false,
      tabActive: 'principal'
    }
  },
  computed: {
    EstadoCivil () {
      return EstadoCivil
    },
    RegimesCasamento () {
      return RegimesCasamento
    },
    EstadoCivilCasado () {
      return ESTADO_CIVIL_CASADO
    },
    isPessoaFisica () {
      return this.juiz.type === TIPO_PESSOA_FISICA
    },
    isEmpresa () {
      return this.juiz.type === TIPO_PESSOA_JURIDICA
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.id && find(this.id || this.juiz.id)
          .then(response => {
            console.log(response.data)
            let data = response.data
            if (data.birthDate && data.birthDate.date) {
              data.birthDate = date.formatDate(data.birthDate.date, 'DD/MM/YYYY')
            }
            // let mock = JSON.parse(JSON.stringify(mockArrematante))
            // console.log(mock)
            // this.arrematante = Object.assign(mock, data)
            // console.log(this.arrematante)
            if (!data.addresses || data.addresses.length === 0) {
              data.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
            }

            if (!data.phoneNumbers || data.phoneNumbers.length < 3) {
              if (!Array.isArray(data.phoneNumbers)) {
                data.phoneNumbers = []
              }
              const len = 3 - Number(data.phoneNumbers.length)
              for (let i = 0; i < len; i++) {
                data.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
              }
            }

            if (!data.emails || data.emails.length === 0) {
              data.emails = [JSON.parse(JSON.stringify(mockEmail))]
            }

            if (!data.documents || data.documents.length === 0) {
              data.documents = [JSON.parse(JSON.stringify(mockDocument))]
            }

            adjustPurposes([
              data.addresses,
              data.phoneNumbers,
              data.contacts,
              data.emails
            ])
            this.juiz = data
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar os dados do juiz, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    save () {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.juiz))

      // Ajusta dados
      let phones = []
      this.juiz.phoneNumbers.forEach((phone, index) => {
        if (phone.phoneNumber && String(phone.phoneNumber).length > 4) {
          phones.push(phone)
        }
      })
      data.phoneNumbers = phones

      let contacts = []
      this.juiz.contacts.forEach((contact, index) => {
        if (contact.value && String(contact.value).length > 2) {
          contacts.push(contacts)
        }
      })
      data.contacts = contacts

      data.addresses.map((address) => {
        if (address.cityId && address.cityId.id) {
          address.cityId = address.cityId.id
        }
      })

      data.documents && data.documents.map((document) => {
        if (document.type && document.type.id) {
          document.type = document.type.id
        }
      })

      if (data.type === TIPO_PESSOA_JURIDICA) {
        // data.documents = null
      }

      this.status = 'Processando dados do juiz'
      let method = data.id ? updateJuiz(data.id, data) : newJuiz(data)
      method
          .then(({data}) => {
            console.log(data)
            this.juiz.id = data.id
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                // this.$refs.timer.start()
                window.setTimeout(() => {
                  this.loading = false
                  this.success = false
                  this.load()
                }, 3000)
                this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    }
  }
}
</script>

<style scoped>

</style>